import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import {
  isLayoutCopyright,
  reactiveTemplateConfigSelector
} from 'common/selectors/theme';
import { getWebInstanceSettingsSelector } from 'common/selectors';

import loadable from '@loadable/component';
const Link = loadable(() => import('common/components/styleguide/Link'));
const CopyrightVariant = loadable(props =>
  import(`common/blocks/copyright/${props.blockVariant}`)
);

class Copyright extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { settings, blockSettings, t } = this.props;

    if (!_.get(blockSettings, 'isCopyrightEnabled')) {
      return null;
    }

    const copyrightData = {
      companyName: _.get(settings, 'name_of_company'),
      copyrightText: (
        <Trans i18nKey="copyrightText">
          serviced by ecommerce system <Link href={'https://www.grandus.sk'} rel={'nofollow sponsored'}>Grandus</Link> by <Link href={'https://www.forbestclients.com'} rel={'nofollow sponsored'}>For Best Clients, s.r.o.</Link>
        </Trans>
      )
    };

    return (
      <CopyrightVariant
        blockVariant={_.get(blockSettings, 'blockVariant')}
        {...copyrightData}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const isCopyrightEnabled = isLayoutCopyright();
  const getWebInstanceSettings = getWebInstanceSettingsSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'CopyrightVariant'
  );
  return (state, props) => {
    return {
      settings: getWebInstanceSettings(state, props),
      blockSettings: {
        isCopyrightEnabled: isCopyrightEnabled(state, props),
        blockVariant: reactiveTemplateConfig(state, props)
      }
    };
  };
};

const CopyrightConnected = connect(makeMapStateToProps)(Copyright);

export default CopyrightConnected;
